<template>
  <div class="model-create">
    <div class="ux__tool is-unselectable">
      <div
        @click="toolDot()"
        :class="{ tool: true, 'tool-dot': true, active: tool_activate_dot }"
      >
        x
      </div>
      <div
        v-if="debug"
        @click="toolU()"
        :class="{ tool: true, 'tool-u': true, active: tool_activate_line_u }"
      >
        u
      </div>
      <div
        v-if="debug"
        @click="toolV()"
        :class="{ tool: true, 'tool-v': true, active: tool_activate_line_v }"
      >
        v
      </div>
      <div
        @click="toolModelCreate()"
        :class="{
          tool: true,
          'tool-v': true,
          active: tool_activate_model_create,
        }"
      >
        new
      </div>
    </div>

    <div class="ux__menu">
      <div v-if="tool_activate_model_create" class="ux__model-new">
        <h1>model</h1>

        <image-upload
          class="image-upload"
          event="BUS_UPLOAD_PICTURE"
          description="drop picture"
        ></image-upload>

        <image-upload
          class="image-upload"
          event="BUS_UPLOAD_MASK"
          description="drop mask"
        ></image-upload>

        <image-upload
          class="image-upload"
          event="BUS_UPLOAD_TEXTURE"
          description="drop texture"
        ></image-upload>
      </div>

      <!--image-upload class="image-upload"
                          event="BUS_UPLOAD_GRID"
                          description="drop .json file"

            ></image-upload-->

      <b-slider
        size="is-small"
        :min="1"
        :max="100"
        v-model="texture_repeat"
        @input="textureRepeatModify()"
      >
      </b-slider>

      <section>
        <b-field>
          <b-input v-model="model_u" maxlength="2"></b-input>
          <b-input v-model="model_v" maxlength="2"></b-input>
          <b-input v-model="dot_size" maxlength="2"></b-input>

          <b-checkbox-button
            v-model="grid_display"
            @click.native="gridDisplay(!grid_display)"
            native-value="Nope"
            type="is-danger"
          >
            <span>grid</span>
          </b-checkbox-button>

          <b-button @click="download()">download image</b-button>
          <b-button @click="modelDownload()">download model</b-button>
          <b-button @click="modelSave()">save</b-button>
        </b-field>
      </section>
    </div>

    <div
      id="scene"
      :class="{ over: isDragOver }"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="drop($event)"
    ></div>
  </div>
</template>

<script>
import EventBus from "../bus";

import Layout from "@abstraktion/abstraktion-lib/visualize/layout/Layout";
import Tool from "@abstraktion/abstraktion-lib/visualize/tool/Tool";
import History from "@abstraktion/abstraktion-lib/visualize/layout/input/History.js";

// import Layout from "../abstraktion-lib/visualize/layout/Layout";
// import Tool from "../abstraktion-lib/visualize/tool/Tool";
// import History from "../abstraktion-lib/visualize/layout/input/History.js";

//import Layout from "abstraktion-lib/visualize/layout/Layout";
//import Tool from "abstraktion-lib/visualize/tool/Tool";

import ImageUpload from "../components/ImageUpload.vue";

import it from "../assets/model/shirt/woman_shirt_texture_1.jpg";
//import BCheckbox from "buefy/src/components/checkbox/Checkbox";

export default {
  name: "ModelCreate",
  components: {
    ImageUpload,
  },
  data() {
    return {
      debug: !true,

      layout: null,
      tool: null,

      texture_repeat: 7,
      model_u: 20,
      model_v: 20,
      dot_size: 15,
      grid_color: 0xff0000,

      isDragOver: false,
      grid_display: true,

      history: null,

      tool_activate_dot: false,
      tool_activate_line_u: false,
      tool_activate_line_v: false,
      tool_activate_model_create: false,
    };
  },
  mounted() {
    //setTimeout(()=>{
    //       window.location.reload()
    //  },10000)
    this.init();
    this.initLogic();

    this.tool_activate_dot = true;

    // debug
    window.layout = this.layout;
    window.h = this.history;
  },
  created() {
    window.addEventListener("keypress", this.keyPress);
  },
  destroyed() {
    window.removeEventListener("keypress", this.keyPress);
  },
  methods: {
    toolModelCreate() {
      this.tool_activate_model_create = !this.tool_activate_model_create;
      localStorage.setItem(
        "model_tool_create",
        this.tool_activate_model_create
      );
    },

    toolDot() {
      this.tool.setDot();
      this.tool_activate_dot = true;
      this.tool_activate_line_u = !true;
      this.tool_activate_line_v = !true;
    },

    toolU() {
      this.tool.setLineU();
      this.tool_activate_line_u = true;
      this.tool_activate_line_v = !true;
      this.tool_activate_dot = !true;
    },

    toolV() {
      this.tool.setLineV();
      this.tool_activate_line_v = true;
      this.tool_activate_line_u = !true;
      this.tool_activate_dot = !true;
    },

    initLogic() {
      /**
       * mouse interaction
       */

      this.layout.setCallbackMouseUp((was_dragging, grid_index) => {
        /**
         *  mouse up after dragging : push to history
         *
         *
         */
        console.log("layout:mouseup", was_dragging, grid_index);

        if (was_dragging && grid_index !== null && grid_index >= 0) {
          //console.table(this.layout.getMemoryGridVertices())

          //console.table( this.history._memory )

          // vue observer remove
          this.history.push(this.layout.getMemoryGridVertices());
          // @todo next : multi dimensional storage + actual
        }
      });

      this.layout.mouse.setMouseDragCallback(() => {
        // if not moving dots
        if (this.layout.controls.enabled) {
          /**
           *
           * operations not on the grid
           *
           */

          // left click
          if (this.layout.mouse.isClickLeft()) {
            //

            if (this.layout.keyboard.isAlt()) {
              // rotate all

              //this.modelLayerRotate( this.layout.getLayerActive() , this.layout.mouse.dragX()/100 + this.layout.mouse.dragY()/1000 )
              this.layout.getModelGrid().mesh.material.map.rotation =
                this.layout.mouse.dragX() / 100 +
                this.layout.mouse.dragY() / 1000;
            } else {
              if (this.layout.keyboard.isControl()) {
                // rotate material
                //this.modelMaterialRotate( this.layout.getLayerActive() , this.layout.mouse.dragX()/10 + this.layout.mouse.dragY()/100 )
              }

              if (this.layout.keyboard.isShift()) {
                // texture size

                let zy = this.layout.mouse.dragY() / 30;

                if (zy < 0) zy = -zy;

                let zx = this.layout.mouse.dragX() / 1000;

                if (zx < 0) zx = -zx;

                //this.modelTextureSize       (  this.layout.getLayerActive() , zx + zy )
              }

              if (this.layout.keyboard.isNop()) {
                // default
                //this.modelMaterialMove      ( this.layout.getLayerActive() , -this.layout.mouse.dragX()/100 , this.layout.mouse.dragY()/100 )
              }
            }

            //

            //this.modelLayerRepeat       ( 0 , this.layout.mouse.dragY() )
          }
        } else {
          // moving dots
        }
      });
    },

    keyPress(e) {
      //console.log(e.ctrlKey,e.keyCode)

      if (e.keyCode == 26 && e.ctrlKey && e.shiftKey) {
        // ctrl + shift + z
        console.log("history +");

        let h = this.history.pullForward(); // object or false if no more history steps

        //console.log('h',h)

        if (h) {
          this.layout.model.getGrid(0).setVertices(h);
        } else {
          this.log(" history steps top ");
        }
      } else if (e.keyCode == 26 && e.ctrlKey) {
        // ctrl + z
        console.log("history -");

        // save last
        if (this.history.isLast()) {
          //console.log('push save last position')
          this.history.push(this.layout.getMemoryGridVertices());
        }

        let h = this.history.pullBackward(); // object or false if no more history steps

        //console.log('h',h)

        if (h) {
          this.layout.model.getGrid(0).setVertices(h);
        } else {
          this.log(" history steps bottom ");
        }
      }
    },

    log(information, data) {
      if (this.debug) {
        console.log(information, data);
      }
    },

    modelSave() {
      console.log("modelSave");

      localStorage.setItem(
        "model_grid",
        JSON.stringify(this.layout.model.getGrid(0).export())
      );
    },

    dragOver() {
      //console.log('dragOver')
      this.isDragOver = true;
    },

    dragLeave() {
      //console.log('dragLeave')
      this.isDragOver = !true;
    },

    drop(e) {
      //console.log('drop',e)

      let files = e.dataTransfer.files;

      let file = files[0];

      if (file.type == "application/json") {
        let reader = new FileReader();

        reader.onload = (f) => {
          let json = JSON.parse(f.target.result);

          console.log(json);
          //let json = JSON.parse(reader.result)

          // @todo code + need to remove former visible grid :

          let model_texture = localStorage.getItem("model_texture");
          let model_mask = localStorage.getItem("model_mask");

          this.layout.setModel(
            model_mask,
            model_texture || it,
            {
              dot: {
                color: this.grid_color,
                size: this.dot_size,
              },
              repeat: this.texture_repeat,
              uv: {
                u: this.model_u, // useless, crushed by import
                v: this.model_v,
              },
              import: json,
            },
            () => {
              console.log("grid changed");

              // push 1st history
              //console.log(this.layout.model)
              //this.history.push( this.layout.model.getGrid(0).getVertices() )

              localStorage.setItem("model_grid", JSON.stringify(json));
            }
          );
        };

        reader.readAsText(file, "UTF-8");
      } else {
        let reader = new FileReader();

        reader.onload = (f) => {
          // detect JSON or image

          //let extension = f.match(/\.[0-9a-z]+$/i)

          // if (file.type == "application/json") {
          //   let src = f.target.result;

          //   let json = JSON.parse(src);

          //   console.log(json);
          // } else {
          // image

          // f.target.result contains the base64 encoding of the image
          let src = f.target.result;

          // set texture
          this.layout.setTexture(src, this.texture_repeat);

          // localstorage save
          try {
            localStorage.setItem("model_texture", src);
            src = null;
          } catch (e) {
            console.log(" error : texture size exceeds ", e);
            src = null;
          }
          //}
        };

        reader.readAsDataURL(file);
      }

      this.isDragOver = !true;
    },

    modelDownload() {
      try {
        const json = JSON.stringify(this.layout.model.getGrid(0).export());

        let a = document.createElement("a");
        let file = new Blob([json], { type: "application/json" });
        a.href = URL.createObjectURL(file);
        a.download = "abstraktion.json";
        a.click();
      } catch (e) {
        console.log(e);
        return;
      }
    },

    download() {
      // https://codepen.io/looeee/pen/GdQpbd

      //if (this.grid_display)
      this.gridDisplay(false);

      var imgData;

      // add timeout ?

      var saveFile = function (strData, filename) {
        var link = document.createElement("a");
        if (typeof link.download === "string") {
          document.body.appendChild(link); //Firefox requires the link to be in the body
          link.download = filename;
          link.href = strData;
          link.click();
          document.body.removeChild(link); //remove the link when done
        } else {
          //location.replace(uri)
        }
      };

      setTimeout(() => {
        try {
          const strMime = "image/jpeg";

          imgData = this.layout.renderer.domElement.toDataURL(strMime);

          saveFile(imgData.replace(strMime, strMime), "abstraktion.jpg");
        } catch (e) {
          console.log(e);
          return;
        }

        if (this.grid_display) this.gridDisplay(true);
      }, 10);
    },

    gridDisplay(boolean) {
      this.tool.displayDotGrid(boolean);
    },

    textureRepeatModify() {
      this.layout.model.setRepeat(this.texture_repeat);

      try {
        localStorage.setItem("model_texture_repeat", this.texture_repeat);
      } catch (e) {
        console.log(" error ", e);
      }
    },

    init() {
      console.log("** init **");

      this.history = new History();

      this.tool = new Tool();
      this.tool.displayDotGrid(true);

      this.layout = new Layout({
        tool: this.tool,
        div_id: "scene",
        is_3d: !true,
        debug: true,
        helpers: false,
        dot_display: true,
        background: {
          color: 0xffffff,
        },
        dot: {
          size: this.dot_size, // used by raycaster
        },
      });

      window.l = this.layout; //@debug

      // image in localstorage ?

      if (localStorage.getItem("model_tool_create") !== null) {
        this.tool_activate_model_create =
          localStorage.getItem("model_tool_create") == "true";
      }

      let model_picture,
        model_mask,
        model_texture = null;

      if (localStorage.getItem("model_texture_repeat") !== null) {
        this.texture_repeat = parseInt(
          localStorage.getItem("model_texture_repeat")
        );
        this.layout.model.setRepeat(this.texture_repeat);
      }

      if (localStorage.getItem("model_picture") !== null) {
        model_picture = localStorage.getItem("model_picture");
        this.layout.setPicture(model_picture);
      }

      if (localStorage.getItem("model_texture") !== null) {
        model_texture = localStorage.getItem("model_texture");
      }

      if (localStorage.getItem("model_mask") !== null) {
        model_mask = localStorage.getItem("model_mask");
        this.layout.setModel(
          model_mask,
          model_texture || it,
          {
            dot: {
              color: this.grid_color,
              size: this.dot_size,
            },
            repeat: this.texture_repeat,
            uv: {
              u: this.model_u, // useless, crushed by import
              v: this.model_v,
            },
            import: JSON.parse(localStorage.getItem("model_grid")),
          },
          () => {
            //console.log( 'callback' )

            // push 1st history
            //console.log(this.layout.model)
            //this.history.push( this.layout.model.getGrid(0).getVertices() )

            localStorage.setItem(
              "model_grid",
              JSON.stringify(this.layout.model.getGrid(0).export())
            );
          }
        );
      }

      /**
       * set picture
       *
       * ops :
       *
       * reset grid
       *
       */
      EventBus.$on("BUS_UPLOAD_PICTURE", (file, image) => {
        this.layout.model.remove(0);

        this.layout.setPicture(image.data);

        try {
          localStorage.setItem("model_picture", image.data);
        } catch (e) {
          console.log(" error : image size exceeds ", e);
        }
      });

      /**
       * set mask
       * save grid (reset)
       */
      EventBus.$on("BUS_UPLOAD_MASK", (file, image) => {
        this.layout.model.remove();

        console.log("//////", image);

        // make uv proportional with image.width image.height information

        let u, v;

        if (image.height > image.width) {
          // |x|

          u = parseInt(this.model_u);
          v = Math.round(image.height / (image.width / u)); // find size of square and fill
        } else {
          console.log("=x=");
          // =x=

          v = parseInt(this.model_v);
          u = Math.round(image.width / (image.height / v));
        }

        console.log(u, v, "****");

        this.layout.setModel(
          image.data,
          localStorage.getItem("model_texture") || it,
          {
            dot: {
              color: 0xaaaaaa,
              size: this.dot_size,
            },
            repeat: this.texture_repeat,
            uv: {
              u: u,
              v: v,
            },
            //import: JSON.parse( localStorage.getItem( 'model_grid' ) )
          },
          () => {
            console.log("callback");

            localStorage.setItem(
              "model_grid",
              JSON.stringify(this.layout.model.getGrid(0).export())
            );
          }
        );

        localStorage.setItem("model_mask", image.data);

        //localStorage.removeItem('model_grid')

        //console.log( this.layout.model.getGrid(0) , '***-*' )
      });

      /**
       * set json
       *
       */
      EventBus.$on("BUS_UPLOAD_GRID", (file, data) => {
        //this.layout.model.remove()

        console.log(file, data, "---");

        this.layout.setModel(
          data,
          localStorage.getItem("model_texture") || it,
          {
            dot: {
              color: 0xeeeeee,
              size: this.dot_size,
            },
            repeat: this.texture_repeat,
            uv: {
              u: this.model_u,
              v: this.model_v,
            },
            import: JSON.parse(localStorage.getItem("model_grid")),
          },
          () => {
            console.log("callback");

            localStorage.setItem(
              "model_grid",
              JSON.stringify(this.layout.model.getGrid(0).export())
            );
          }
        );

        localStorage.setItem("model_grid", data);

        //localStorage.removeItem('model_grid')

        //console.log( this.layout.model.getGrid(0) , '***-*' )
      });

      /**
       * set texture
       *
       * save texture
       */
      EventBus.$on("BUS_UPLOAD_TEXTURE", (file, image) => {
        //this.layout.setModel( image.data , image.data )

        // if model set

        //console.log(image.data)

        try {
          localStorage.setItem("model_texture", image.data);
        } catch (e) {
          console.log(" error : texture size exceeds ", e);
        }

        this.layout.setTexture(image.data, this.texture_repeat); // load texture
      });
    },

    destroyed() {
      console.log("**** destroyed ****");
    },
  },
};
</script>

<style lang="scss" scoped>
.b-slider {
  margin: 0em 0;
}

.model-create {
  #scene {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 1s;
    &.over {
      filter: brightness(50%);
    }
  }

  .ux {
    &__model-new {
      margin: 1rem;
    }

    &__tool {
      position: fixed;
      top: 50%;
      left: 1rem;
      transform: translateY(-50%);
      background-color: #fff;
      padding: 2px;
      border-radius: 2px;
      border: 1px solid #eee;

      .tool {
        width: 3rem;
        height: 3rem;
        margin: 2px;
        transition: all 1s;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: rgba(0, 0, 0, 0.18);
        }

        &.active {
          background-color: rgba(0, 0, 0, 0.8);
          color: #fff;
        }

        &--u {
        }

        &--v {
        }

        &--dot {
        }
      }
    }

    &__menu {
      background-color: rgba(255, 255, 255, 0.8);
      width: 100%;
      padding: 1rem 1rem 0rem 1rem;

      h1 {
        margin: 1rem;
      }

      .image-upload {
        display: initial;
        margin: 1rem;
        min-width: 12rem;
      }

      ul {
        display: flex;
        max-width: 80%;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        height: auto;

        li {
          &:first-child {
            font-weight: bold;
          }
          margin-right: 2rem;
          cursor: pointer;
          transition: all 1s;
          &:hover {
            opacity: 0.5;
          }
          margin: 1rem;
        }
      }
    }
  }
}
</style>