<template>

    <div class="image-upload">

        <b-upload v-model="dropFile"
                  name="upload"
                  type="is-light"
                  multiple
                  drag-drop
                    @input="importImage()"
        >

            <section class="section">
                <div class="content has-text-centered">
                    {{ this.description }}
                </div>
            </section>

        </b-upload>


    </div>

</template>

<script>

    // app parameters


    // bus
    // events   UPLOAD_IMAGE

    import EventBus from '../bus'



    export default {

        name: 'ComponentsModelUploadImage',

        components: {

        },

        data () {

            return {

                debug: true,

                success: false,
                dropFile: [],
                file: {
                    name: ''
                },
                image: {
                    width: 0,
                    height: 0,
                    data: ''
                },
                uploadProgression: 0

            }

        },

        props: [
            'event',
            'id',
            'description'
        ],

        created: function () {

            // listen to upload progression and display it
            /*EventBus.$on( 'UPLOAD_IMAGE_PROGRESSION' , ( percentage ) => {

                this.uploadProgression = percentage

            })*/

        },

        methods: {

            uploadImage() {

                console.log('upload')

            },

            importImage() {

                //this.dropFile = []

                //if (input.files && input.files[0]) {

                // create a new FileReader to read this image and convert to base64 format
                var reader = new FileReader()

                reader.onload = (e) => {

                    this.image.data = e.target.result
                    this.file.name = this.getImageLatest().name


                    /*if (this.file.name == '')  // empty : take file name
                        this.file.name = this.getImageLatest()
                            .name.split('.').slice(0, -1).join('.')
                            .replace(/-/g,' ').replace(/_/g,' ')*/

                    // get size

                    let im = new Image()

                    im.src =  this.image.data

                    im.onload = () => {

                        this.image.width = im.width
                        this.image.height = im.height

                        EventBus.$emit( this.event , this.dropFile[0] , this.image )

                        //console.log(this.image.width,this.image.height)

                        this.setFinished()

                    }

                }

                // Start the reader job - read file as a data url (base64 format)
                reader.readAsDataURL( this.getImageLatest() )

                // }//

                //console.log( this.getImageLatest() )



            },

            getUploaderId() {

                return 'upload_' + this.id

            },

            setFinished() {

                this.success = true

                //this.dropFile = []

            },

            getImageLatest() {

                return this.dropFile[ this.dropFile.length-1 ]

            }

        },

        /*watch: {

            dropFile( asset ) {

                if (this.debug)
                    console.log(asset)

                //this.$emit('imageUpload', asset)

                //this.$parent.imageUpload( asset )

                this.importImage()

            },

        },*/

    }

</script>

<style lang="scss">

    .upload-draggable {
        min-width: 200px;
    }

    .image-upload {

        position: relative;

        &__icon-upload {
            background-color: unset;
            margin:20px;
            opacity: .4;
        }

        &__progressbar {
            width:100%;
            position: absolute;
            top: 0;
            z-index: 50;
            width: calc( 100% - 12px );
            margin: 6px;
            opacity: .7;
        }

        font-size:.8rem;
        text-transform: uppercase;

        .notification {
            font-size:.8rem;
            padding: 9px 13px;
        }

        .image-preview {
            //background-color: #0f0;
        }

        .section {

            font-size:.8rem;
            text-transform: uppercase;
        }


        &__image {

            position: relative;

            &.void {
                min-height: 400px;
            }


            .image-upload__mask-preview {
                position: absolute;
                top: 0px;
                z-index: 10;
                background-color: rgba(255, 0, 0, 0.1);
                height:100%;
                box-sizing: border-box;
            }

            .image-upload__image-preview {
                //background-color: rgba(255, 255, 255, 0.4);
                background-color: rgba(255, 0, 0, 0.1);

                height:100%;
                margin:0px;
                padding:0px;
            }

        }


        &__zone {

            position: absolute;

            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;

            z-index: 15;
            //background-color: rgba(255, 0, 0, 0.2);
            font-size:.8rem;
            line-height: .9rem;
            color:#fff;
            text-transform: uppercase;

            //padding:15px;
            box-sizing: border-box;

            .section,p {

                font-size:.8rem;
                text-transform: uppercase;
            }

            .content , .field, .upload {
                height: 100%;
            }

            .content-text {
                padding: 30px;
            }

            .upload {
                background-color: rgba(255, 255, 255, 0.1);

            }

            .upload-draggable {
                cursor: pointer;
                padding: 0.25em;
                border: 1px dashed rgba(255, 255, 255, 0.39);
                border-radius: 0px;

            }

        }

    }

    .upload .upload-draggable {
        border-radius: 0px !important;
    }

</style>